import React from 'react';
import './Watermark.css';
import {PublicActivation} from "../../model";

type P = {
    activation: PublicActivation
}

const Watermark = ({activation}: P) => {
    return (
        <div className="marker-watermark">
            <img
                src={activation?.previewImage ?? activation?.image?.url}
                alt={"marker image for orientation"}
            />
        </div>
    )
}

export default Watermark;
