import {useEffect} from "react";
import {useCollectEventMutation} from "../../../common/queries";

export const usePlaybackAnalytics = (isPlaying: boolean, markerVideo?: HTMLVideoElement) => {
    const {mutate: collectEvent} = useCollectEventMutation()
    let playedSeconds = 0;
    let playbackCounter: NodeJS.Timer | undefined;

    const startPlaybackDurationTracking = function () {
        playbackCounter = setInterval(() => {
            if (!markerVideo?.paused) {
                playedSeconds += 1;
            } else {
                stopPlaybackTracking();
            }
        }, 1000);
    };

    const stopPlaybackTracking = () => {
        collectEvent({eventType: "videoEnd", data: playedSeconds})
        playedSeconds = 0;
        clearInterval(playbackCounter);
    }

    useEffect(() => {
        if (isPlaying) {
            startPlaybackDurationTracking();
        } else if (playbackCounter) {
            stopPlaybackTracking();
        }
    }, [isPlaying]);
}
