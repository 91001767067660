import { Slider } from "antd";
import React, {useEffect, useState} from "react";
import { formatDuration } from "../../utils";

interface Props {
  videoElement: HTMLVideoElement;
  onSeek: (currentTime: number) => void;
}

const VideoSeeker = ({ videoElement, onSeek }: Props) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    initSeekerChange();
  }, []);

  const initSeekerChange = () => {
    updateVideoTime();
  };

  const updateVideoTime = () => {
    requestAnimationFrame(() => {
      const currentTime = videoElement.currentTime ?? 0;
      setCurrentTime(currentTime);
      updateVideoTime();
    });
  };

  return (
      <Slider
          defaultValue={0}
          value={currentTime}
          step={0.01}
          min={0}
          max={videoElement.duration ?? 0}
          tooltip={{ formatter: (val?: number) => (val ? formatDuration(val ?? 0) : "-") }}
          onChange={(val) => onSeek(val)}
      />
  );
};
export default VideoSeeker;
