import {environment} from "../../../environment/.env";
import {retryUntilSuccess} from "../../utils/promise";

export const collect = async (productId: string, body: unknown)=> {
    const url = `${environment.analyticsURL}/products/${productId}/collect`;
    const headers = {
        authorization: `Basic ${environment.authToken}`,
        "Content-Type": "application/json",
    };

    const collectOperation = () => fetch(url, {
        headers,
        credentials: "include",
        method: "POST",
        body: JSON.stringify(body),
    }).catch((error) => {
        console.log("Error collecting event: ", error);
        return null;
    })

    await retryUntilSuccess(collectOperation)
}
