import {useMutation} from "@tanstack/react-query";
import {collectCtaFollow} from "../../services/analytics/collectCtaFollow";
import {useContext} from "react";
import {ActivationContext} from "../../context";
import {collectCtaAuto} from "../../services/analytics/collectCtaAuto";
import {collectVideoStart} from "../../services/analytics/collectVideoStart";
import {collectCtaManual} from "../../services/analytics/collectCtaManual";
import {collectPageView} from "../../services/analytics/collectPageView";
import {collectVideoStop} from "../../services/analytics/collectVideoStop";

type AnalyticsEventType = 'ctaAuto' | 'ctaFollow' | 'ctaManual' | 'pageView' | 'videoStart' | 'videoEnd';

const getOperation = (event: AnalyticsEventType, activationId: string, data?: number) => {
    switch (event) {
        case "ctaAuto":
            return collectCtaAuto(activationId);
        case "ctaFollow":
            return collectCtaFollow(activationId);
        case "ctaManual":
            return collectCtaManual(activationId);
        case "pageView":
            return collectPageView(activationId);
        case "videoStart":
            return collectVideoStart(activationId);
        case "videoEnd":
            if (data == null) {
                throw new Error('Missing data for collectEvent');
            }
            return collectVideoStop(activationId, data);
    }
}

export const useCollectEventMutation = () => {
    const {activation} = useContext(ActivationContext);
    return useMutation({
        async mutationFn({eventType, data}: { data?: number, eventType: AnalyticsEventType }) {
            console.log({activation})
            const activationId = activation?.product?._id;
            if (!activationId) {
                throw new Error('Activation id unavailable')
            }
            await getOperation(eventType, activationId, data)
        },
    });
}
