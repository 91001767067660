import {LinkOutlined} from "@ant-design/icons";
import React from "react";
import './Header.css'

const Header = ({onRedirectClick}: {onRedirectClick: () => void}) => {
    return (<div className="header">
        <div
            className="header__action clickable"
            onClick={onRedirectClick}
        >
            <LinkOutlined className={"header__action"}/>
        </div>
    </div>)
}

export default Header;
