import {StaticActivation} from "../../model";

export const staticActivations: StaticActivation[] = [
    {
        name: "test-1",
        marker:
            "/markers/test-1-marker",
        video:
            "/markers/HornetsFans.mp4",
        maskImage:
            "/markers/test-1-mask.jpg",
        redirectImage: "/markers/test-1-marker.png",
        product: {
            meta: {
                videoAttributes: {
                    objectFit: "cover",
                },
                redirect: {
                    description: "",
                    title: "",
                    button: "Click here to enter",
                    url: "youtu.be/ISXMfd4b7dE",
                    image: '63e2769e8a2dbb5ef1b2bb3b',
                },
            },
            _id: "63e2762a689a7000127e3372",
        },
        image: {
            url: "/markers/test-1-marker.png",
            width: 5700,
            height: 2700,
            dpi: 72,
        },
    },
    {
        name: "test-2",
        marker:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/marker/test-2-marker",
        video:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/video/test-2-video.mp4",
        maskImage:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/marker-image/test-2-mask.jpg",
        redirectImage: "https://videobomb-development-media-bucket.s3.amazonaws.com/redirect/dr-pepper.png",
        product: {
            meta: {
                videoAttributes: {
                    objectFit: "cover",
                },
                redirect: {
                    description: "",
                    title: "",
                    button: "Click here to enter",
                    url: "youtu.be/ISXMfd4b7dE",
                    image: '63e2769e8a2dbb5ef1b2bb3b'
                },
            },
            _id: "63e2762a689a7000127e3372",
        },
        image: {
            url: "https://videobomb-development-media-bucket.s3.amazonaws.com/marker-image/test-2-marker.png",
            width: 1496,
            height: 1497,
            dpi: 72,
        },
    },
    {
        name: "test-3",
        marker:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/marker/test-3-marker",
        video:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/video/test-3-video.mp4",
        maskImage:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/marker-image/test-3-mask.jpg",
        redirectImage: "https://videobomb-development-media-bucket.s3.amazonaws.com/redirect/dr-pepper.png",
        product: {
            meta: {
                videoAttributes: {
                    objectFit: "cover",
                },
                redirect: {
                    description: "",
                    title: "",
                    button: "Click here to enter",
                    url: "youtu.be/ISXMfd4b7dE",
                    image: '63e2769e8a2dbb5ef1b2bb3b'
                    // image: {
                    //   id: "63e2769e8a2dbb5ef1b2bb3b",
                    //   path: "redirect/dr-pepper.png",
                    // },
                },
            },
            _id: "63e2762a689a7000127e3372",
        },
        image: {
            url: "https://videobomb-development-media-bucket.s3.amazonaws.com/marker-image/test-3-marker.png",
            width: 2160,
            height: 2160,
            dpi: 72,
        },
    },
    {
        name: "nashville",
        marker:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/marker/nashville",
        video:
            "https://videobomb-development-media-bucket.s3.amazonaws.com/video/nashville-compressed - SD 480p.mov",
        redirectImage: "https://videobomb-development-media-bucket.s3.amazonaws.com/redirect/dr-pepper.png",
        product: {
            meta: {
                videoAttributes: {objectFit: "cover"},
                redirect: {
                    description: "",
                    title: "Win an autographed jersey",
                    button: "Click here to enter",
                    url: "youtu.be/ISXMfd4b7dE",
                    image: '63e2769e8a2dbb5ef1b2bb3b'
                },
                curvedMarker: {
                    cylinderDiameter: 66,
                    markerWidth: 25,
                    overlayWidth: 150,
                    overlayHeight: 136,
                    yOffset: 0,
                },
            },
            _id: "63e2762a689a7000127e3372",
        },
        image: {
            url: "https://videobomb-development-media-bucket.s3.amazonaws.com/marker-image/nashville.png",
            width: 529,
            height: 1999,
            dpi: 72,
        },
    },
];
