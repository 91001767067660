import React, {createContext, PropsWithChildren, useMemo, useState} from "react";
import {PublicActivation} from "../../model";

export const ActivationContext = createContext<{
    activation: PublicActivation | undefined;
    setActivation: (activation: PublicActivation | undefined) => void;
}>({
    activation: undefined,
    setActivation: () => {
        throw new Error('Not implemented')
    }
});

const ActivationProvider = ({children}: PropsWithChildren) => {
    const [activation, setActivation] = useState<PublicActivation | undefined>(undefined);
    const providerValue = useMemo(() => {
        return {
            activation,
            setActivation,
        }
    }, [activation])
    return <ActivationContext.Provider value={providerValue}>
        {children}
    </ActivationContext.Provider>
}

export default ActivationProvider;
