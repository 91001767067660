import React from 'react';
import './Progress.css';
import {useRandomProgress} from "./hooks/useRandomProgress";

type Props = {
    indetermined: boolean;
    max: number;
}

export const Progress2 = ({indetermined, max}: Props) => {
    const progress = useRandomProgress({indetermined, max});

    const percentOfMax = function () {
        return (progress * 100) / max;
    };

    return (
        <div className="progress">
            <div
                className={['progress__bar', indetermined ? 'progress__indetermined' : ''].join(' ')}
                style={{width: `${indetermined ? 30 : percentOfMax()}%`}}
            ></div>
        </div>

    )
}

export default Progress2;
