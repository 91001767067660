import React, {useContext, useState} from 'react';
import {TrackingEngine} from "../TrackingEngine";
import {ArEngineContext} from "../../common/context";
import {Header, Watermark} from "../../ui";
import {ActivationModal} from "./components";
import {PublicActivation} from "../../model";
import {VideoPlayer} from "../VideoPlayer";
import {useCollectEventMutation} from "../../common/queries";

type P = {
    activation: PublicActivation;
}

const ARTrackingContent = ({activation}: P) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const {markerDetected} = useContext(ArEngineContext);
    const {mutate: collectEvent} = useCollectEventMutation();

    const onVideoEnd = () => {
        collectEvent({ eventType: 'ctaAuto' })
        setIsOpenModal(true);
    }

    const onRedirectClick = () => {
        collectEvent({ eventType: 'ctaManual' })
        setIsOpenModal(true)
    }

    return <>
        <Header onRedirectClick={onRedirectClick}/>
        {!markerDetected && <Watermark activation={activation}/>}
        <ActivationModal activation={activation} isOpen={isOpenModal} onCancel={() => setIsOpenModal(false)}/>
        <TrackingEngine activation={activation}/>
        <VideoPlayer activation={activation} onVideoEnd={onVideoEnd} />
    </>;
}

export default ARTrackingContent;
