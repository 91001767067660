import React, {useContext, useEffect} from 'react';
import {PublicActivation} from "../../model";
import {ArEngineContext} from "../../common/context";
import {InfoScreen, Loading} from "../../ui";
import {useScreenResizeObserver} from "./hooks";

type P = {
    activation?: PublicActivation
}

const TrackingEngine = ({activation}: P) => {
    const { initArEngine, initialized, isError, arEngine } = useContext(ArEngineContext);
    useScreenResizeObserver({arEngine, initialized});

    useEffect(() => {
        if (activation) {
            initArEngine(activation)
        }
    }, [activation]);

    if (isError) {
        return <InfoScreen
            title={"Error loading activation"}
            message={"Activation could not be loaded."}
        />
    }

    return <div>
        {!initialized && <Loading indetermined={false} />}
        <div id="container"></div>
    </div>;
}

export default TrackingEngine;
