import React from 'react';
import './Loading.css';
import {Progress} from "../Progress";

type Props = {
    indetermined?: boolean
}

const Loading = ({indetermined = true}: Props) => {
    return (
        <div className="loading">
            <div className="loading__progress">
                <Progress indetermined={indetermined} max={100}/>
            </div>
            <div className="loading__message">Loading AR experience</div>
        </div>
    )
}

export default Loading;
