import React from "react";
import './ToolbarInstructions.css'

function parseNewLines(value: string) {
    return value.replace(/\n/g, '<br/>');
}

type P = {
    instructions?: string;
}

const defaultInstructions = 'Center the image face in the camera';

const ToolbarInstructions = ({instructions}: P) => {
    if (instructions) {
        return (<div className="toolbar toolbar-instructions">
            <div className='instructions-title'>
                Instructions:
            </div>
            <div dangerouslySetInnerHTML={{__html: parseNewLines(instructions)}}></div>
        </div>)
    }

    return (<div className="toolbar">
        <div className='instructions-title'>
            {defaultInstructions}
        </div>
    </div>)
}

export default ToolbarInstructions
