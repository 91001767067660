import {useEffect} from "react";

export const useInitMarkerVideo = ({markerVideo, onVideoEnded}: {markerVideo?: HTMLVideoElement, onVideoEnded: () => void}) => {
    useEffect(() => {
        if (!markerVideo) {
            return;
        }

        const fullscreenContainer = document.querySelector<HTMLDivElement>(
            ".player__fullscreen"
        );
        if (!fullscreenContainer) {
            throw new Error('Fullscreen container not found');
        }

        markerVideo.loop = false;
        markerVideo.preload = 'auto';
        markerVideo.playsInline = true;
        markerVideo.onended = onVideoEnded;
        markerVideo.load();
        fullscreenContainer.appendChild(markerVideo);

    }, [markerVideo]);
}
