import React from 'react';
import {ToolbarControls, P as ToolbarControlProps} from './ToolbarControls';
import {ToolbarInstructions} from './ToolbarInstructions';

export type Props = ToolbarControlProps & {
    mode: 'instructions' | 'playback';
    instructions?: string;
};

const Toolbar = (props: Props) => {
    const {mode, instructions, isFullscreen} = props;

    if (mode === 'playback' || isFullscreen) {
        return <ToolbarControls {...props}/>
    }

    if (mode === 'instructions') {
        return <ToolbarInstructions instructions={instructions} />
    }

    return <>Unsupported configuration</>
};

export default Toolbar;
