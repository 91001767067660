const withRetry = async (operationFactory: (...args: unknown[]) => Promise<unknown>, maxRetries: number, delay: number) => {
    let retries = 0;

    while (retries < maxRetries) {
        try {
            return await operationFactory();
        } catch (error: unknown) {
            console.error(`Failed with error: ${error}`);
            retries++;
            await new Promise((resolve) => setTimeout(resolve, delay));
        }
    }

    throw new Error(`Maximum retries exceeded: ${maxRetries}`);
};


export const retryUntilSuccess = async (operationFactory: () => Promise<unknown>) => {
    return withRetry(operationFactory, Number.MAX_VALUE, 500)
}
