import {useQuery} from "@tanstack/react-query";
import {checkAndroidWebView} from "../../services";

/**
 * Check existence of header 'x-requested-with' in request, determining it's executed inside android web view.
 * Workaround for Android if page is opened in Instagram or any other integrated browser.
 */
export const useCheckAndroidViewQuery = () => useQuery({
    queryKey: ['viewCompatibility'],
    async queryFn() {
        const response = await checkAndroidWebView()
        return Boolean(response["web-view"])
    }
})
