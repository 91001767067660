import {PublicActivation} from "../../../model";
import React, {useState} from "react";
import {useCollectEventMutation} from "../../../common/queries";
import {RedirectModal} from "../../../ui";

type P = {
    activation: PublicActivation;
    isOpen: boolean;
    onCancel: () => void;
};

const ActivationModal = ({activation, isOpen, onCancel}: P) => {
    const [isRedirecting, setIsRedirecting] = useState(false);
    const {mutateAsync: collectEvent} = useCollectEventMutation();

    const handleRedirect = async () => {
        setIsRedirecting(true);
        const redirectUrl = activation?.product?.meta?.redirect?.url
        if (!redirectUrl) {
            console.error('Error opening modal: Missing redirect URL')
            return;
        }
        await collectEvent({ eventType: 'ctaFollow' })
        window.open(`https://${redirectUrl}`, '_self');
        setIsRedirecting(false);
    }

    return <RedirectModal
        content={activation?.product?.meta?.redirect?.description ?? ""}
        buttonText={activation?.product?.meta?.redirect?.button ?? ""}
        title={activation?.product?.meta?.redirect?.title ?? ""}
        imageUrl={activation?.redirectImage ?? ""}
        redirectUrl={activation?.product?.meta?.redirect?.url ?? ""}
        onCancel={onCancel}
        onRedirect={handleRedirect}
        open={isOpen}
        loading={isRedirecting}
    />
}

export default ActivationModal;
