import {useQuery} from "@tanstack/react-query";
import {PublicActivation} from "../../model";
import {ActivationLoadParams, getActivation} from "../../services";

export const useGetActivationQuery = ({id, video, marker}: ActivationLoadParams) => {
    return useQuery<PublicActivation>({
        queryKey: ['activation'],
        async queryFn() {
            return getActivation({id, video, marker});
        },
        enabled: Boolean(id)
    })
}
