import {useEffect, useMemo, useState} from "react";

const randomNumber = (min: number, max: number) => {
    if (max < min) throw new Error('Max need to be lower than min');
    return Math.random() * (max - min) + min;
};

export const useRandomProgress = ({indetermined, max}: {indetermined: boolean, max: number}) => {
    const [progress, setProgress] = useState(0);
    let timeoutId: NodeJS.Timeout;

    const almostEnd = useMemo(() => max * 0.9, [max]);

    useEffect(() => {
        if (indetermined) {
            resetProgress();
        } else {
            startProgress();
        }
        return () => {
            clearTimeout(timeoutId);
        }
    }, [indetermined])


    const startProgress = () => {
        const rndTime = getRandomTime();
        timeoutId = setTimeout(() => {
            if (indetermined) {
                resetProgress();
                return;
            }

            setProgress((progress) => {
                const newProgress = getRandomProgress() + progress;
                if (newProgress >= almostEnd) {
                    return almostEnd;
                } else {
                    return newProgress;
                }
            })

            startProgress();
        }, rndTime);
    }

    const getRandomProgress = () => {
        return Math.min(randomNumber(10, 30), almostEnd);
    }

    const getRandomTime = () => {
        return randomNumber(0.5, 1) * 1200;
    }

    const resetProgress = () => {
        setProgress(10);
    }

    return progress;
}
