import React from 'react';
import ARTracking from "./features/ARTracking/ARTracking";
import {useCheckAndroidViewQuery, useGetQueryParams} from "./common/queries";
import {InfoScreen, Loading} from "./ui";
import {ArEngineProvider} from "./common/context";
import "./App.css";

const App = () => {
    const {activation: activationId} = useGetQueryParams();
    const {data: isAndroidView, isLoading} = useCheckAndroidViewQuery();

    if (isLoading) {
        return <Loading />
    }

    if (isAndroidView) {
        return <InfoScreen
            image={"images/open-browser.svg"}
            title={"Enjoy the full AR experience "}
            message={
                "It's recommended that you open the app in your default browser by clicking on this button in the top right corner."
            }
        />
    } else if (navigator.userAgent.includes("Instagram")) {
        /* Workaround: iOS instagram webview scroll to remove bar overlapping toolbar  */
        window.scrollTo(0, 2000);
    }

    if (!activationId) {
        return <InfoScreen
            title={"Error loading activation"}
            message={"Activation is not present or activation data are invalid"}
        />;
    }

    return <ArEngineProvider>
        <ARTracking activationId={activationId}/>
    </ArEngineProvider>
}

export default App;
