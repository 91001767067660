import React, {useContext, useEffect} from 'react';
import {InfoScreen, Loading} from "../../ui";
import {useGetActivationQuery, useGetQueryParams} from "../../common/queries";
import {ActivationContext} from "../../common/context";
import ARTrackingContent from "./ARTrackingContent";
import {useCollectPageViewMutation} from "../../common/queries/analytics/useCollectPageViewMutation";
import {PublicActivation} from "../../model";

type P = {
    activationId: string;
    activationParams?: Partial<Pick<PublicActivation, 'video' | 'marker'>>
}

const ARTracking = ({activationId}: P) => {
    const { video, marker } = useGetQueryParams();
    const {setActivation} = useContext(ActivationContext);
    const {
        data: activation,
        isLoading: isActivationLoading,
        isError: activationLoadingError
    } = useGetActivationQuery({id: activationId, video, marker})
    const {mutate: collectPageView} = useCollectPageViewMutation();

    useEffect(() => {
        if (!activation) {
            return;
        }
        setActivation(activation)
        collectPageView({activationId})
    }, [activation])

    if (isActivationLoading) {
        return <Loading />
    }

    if (activationLoadingError) {
        return <InfoScreen
            title={"Error loading activation"}
            message={"Activation is does not exist or activation data are invalid"}
        />
    }

    return <>
        <ARTrackingContent activation={activation}/>
    </>;
}

export default ARTracking;
