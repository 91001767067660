import React, {useContext, useEffect, useRef, useState} from "react";
import {ArEngineContext} from "../../common/context";
import {Toolbar, VideoSeeker} from "../../ui";
import {PublicActivation} from "../../model";
import './VideoPlayer.css';
import {useCollectEventMutation} from "../../common/queries";
import {useInitMarkerVideo, usePlaybackAnalytics} from "./hooks";

const VideoPlayer = ({activation, onVideoEnd}: { activation: PublicActivation, onVideoEnd: () => void }) => {
    const {markerVideo, markerDetected} = useContext(ArEngineContext);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [lastPlayback, setLastPlayback] = useState<boolean>(false);

    const {mutate: collectEvent} = useCollectEventMutation()
    const fullscreenVideoRef = useRef<HTMLVideoElement | null>(null)

    const [activeVideoElement, setActiveVideoElement] = useState<HTMLVideoElement | null>(null)

    usePlaybackAnalytics(isPlaying, markerVideo);
    useInitMarkerVideo({
        markerVideo,
        onVideoEnded: () => {
            onVideoEnd();
            setIsPlaying(false);
            onSeekChange(0);
        }
    })

    // TODO: refactor to hook
    useEffect(() => {
        if (!markerVideo || !fullscreenVideoRef.current) {
            return;
        }
        setActiveVideoElement(markerVideo);
        fullscreenVideoRef.current.playsInline = true;
        fullscreenVideoRef.current.loop = false;
        fullscreenVideoRef.current.preload = 'auto';
        fullscreenVideoRef.current.load();
    }, [markerVideo, fullscreenVideoRef]);

    // Video playback on marker losing
    useEffect(() => {
        if (isFullscreen) {
            return;
        }
        if (!markerDetected) {
            setLastPlayback(isPlaying);
            setIsPlaying(false);
            markerVideo?.pause();
        } else {
            if (lastPlayback) {
                markerVideo?.play();
                setIsPlaying(true);
            }
        }
    }, [markerDetected, isFullscreen]);

    if (!markerVideo) {
        return <></>;
    }

    const onSeekChange = (value: number) => {
        if (activeVideoElement) {
            activeVideoElement.currentTime = value;
        }
    };

    const onPause = () => {
        activeVideoElement?.pause();
        setIsPlaying(false);
    };

    const onPlay = async () => {
        await activeVideoElement?.play();
        setIsPlaying(true);
        collectEvent({eventType: 'videoStart'})
    };

    const onFullscreen = () => {

        if (!fullscreenVideoRef.current) {
            return;
        }
        swapActiveVideo(fullscreenVideoRef.current, markerVideo);
        setIsFullscreen(true);
    };

    const onMinimize = () => {
        if (!fullscreenVideoRef.current) {
            return;
        }
        swapActiveVideo(markerVideo, fullscreenVideoRef.current);
        setIsFullscreen(false);
    };

    const swapActiveVideo = (newActiveVideo: HTMLVideoElement, oldActiveVideo: HTMLVideoElement) => {
        newActiveVideo.currentTime = oldActiveVideo.currentTime;
        oldActiveVideo.pause();

        if (isPlaying) {
            newActiveVideo.play();
        }

        setActiveVideoElement(newActiveVideo)
    }

    return (<div className="player">
        <div
            className="player__fullscreen"
            style={isFullscreen ? {} : {display: "none"}}
        >
            <video src={markerVideo.src} ref={fullscreenVideoRef}/>
        </div>
        <div className="player__controls">
            <div className="player__seeker">
                {((markerDetected || isFullscreen) && activeVideoElement) && (
                    <VideoSeeker
                        videoElement={activeVideoElement}
                        onSeek={(value) => onSeekChange(value)}
                    />
                )}
            </div>
            <Toolbar
                mode={markerDetected ? 'playback' : 'instructions'}
                instructions={activation?.product?.meta?.customInstructions}
                isPlaying={isPlaying}
                isFullscreen={isFullscreen}
                onPause={onPause}
                onPlay={onPlay}
                onFullscreen={onFullscreen}
                onMinimize={onMinimize}
            />
        </div>
    </div>)
}

export default VideoPlayer;
