import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "./lib/query";
import App from "./App";
import ActivationProvider from "./common/context/activationContext";

const root = ReactDOM.createRoot(
    document.getElementById('root')!,
);
root.render(
    <ActivationProvider>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </ActivationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
