import React, {PropsWithChildren, useMemo, useState} from 'react';
import {createContext} from "react";
import {ThreeAREngine} from "tracking-engine-lib";
import {PublicActivation} from "../../model";
import {getThreeMarker} from "../models";

type ArEngineContextData = {
    arEngine?: ThreeAREngine;
    initArEngine: (_: PublicActivation) => void;
    markerDetected: boolean;
    initialized: boolean;
    isError: boolean;
    markerVideo?: HTMLVideoElement;
}

export const ArEngineContext = createContext<ArEngineContextData>({
    arEngine: undefined,
    initArEngine: () => {
        throw new Error("initArEngine not implemented.");
    },
    markerDetected: false,
    initialized: false,
    isError: false,
});

export const ArEngineProvider = ({children}: PropsWithChildren) => {
    const [arEngine, setArEngine] = useState<ThreeAREngine | undefined>(undefined);
    const [markerDetected, setMarkerDetected] = useState<boolean>(false);
    const [initiated, setInitiated] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [markerVideo, setMarkerVideo] = useState<HTMLVideoElement | undefined>(undefined);

    const initArEngine = (activation: PublicActivation) => {
        console.log('initArEngine', activation);
        const container = document.getElementById('container') as HTMLDivElement;
        const marker = getThreeMarker(activation);
        const arEngine = new ThreeAREngine(
            marker,
            container,
            trackingOptions,
        );

        arEngine.start();
        setArEngine(arEngine)
    }

    const providerValue = useMemo(() => ({
        arEngine,
        markerDetected,
        initialized: initiated,
        markerVideo,
        isError,
        initArEngine
    }), [arEngine, markerDetected, initiated, markerVideo, isError])



    const trackingOptions = {
        markerNotFoundCb: () => {
            // onMarkerLost();
            setMarkerDetected(false);
        },
        markerFoundCb: () => {
            // onMarkerFound();
            setMarkerDetected(true);
        },
        endLoadingCb: (videoElement: HTMLVideoElement) => {
            setInitiated(true);
            setMarkerVideo(videoElement);
        },
        libInitStarted: () => {
            // setState({libLoaded: true});
        },
        libInitError: () => {
            setIsError(true);
            // setState({loadingError: true, loading: false});
        },
    }

    return <ArEngineContext.Provider value={providerValue}>
        {children}
    </ArEngineContext.Provider>;
}
