import React, { type FC } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './InfoScreen.css';

type Props = { title: string; message?: string, link?: { label: string, url: string }, image?: string };

export const InfoScreen: FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => (
    <div className='loading-error'>
        <div className={'loading-error__icon'}>
            {
                !props.image && <ExclamationCircleOutlined/>
            }
            {
                props.image && <img src={props.image} alt="Info screen image"/>
            }
        </div>

        <span className='loading-error__title'>{props.title}</span>
        {props.message &&
            <span className='loading-error__message'>{props.message}</span>
        }
        {props.link && <a className="loading-error__link" href={props.link.url} target="_blank"
                          rel="noreferrer">{props.link.label}</a>
        }
    </div>
);

export default InfoScreen;
