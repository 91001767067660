import {useMutation} from "@tanstack/react-query";
import {collectPageView} from "../../services/analytics/collectPageView";

export const useCollectPageViewMutation = () => {
    return useMutation({
        async mutationFn({activationId}: { activationId: string }) {
            await collectPageView(activationId)
        },
    });
}
