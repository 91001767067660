import {ThreeAREngine} from "tracking-engine-lib";
import {useEffect, useState} from "react";

export const useScreenResizeObserver = ({ arEngine, initialized }: {arEngine?: ThreeAREngine, initialized?: boolean}) => {
    const [orientation, setOrientation] = useState<'landscape' | 'portrait'>();
    useEffect(() => {
        if (!arEngine || !initialized) {
            return;
        }
        window.onresize = () => {
            const {innerHeight, innerWidth} = window;
            const screenOrientation =
                innerWidth > innerHeight ? "landscape" : "portrait";
            if (screenOrientation !== orientation) {
                setOrientation(screenOrientation);
                arEngine.onOrientationChanged();
            }
        };
    }, [orientation, arEngine, initialized])
}


