import {PublicActivation} from "../model";
import {staticActivations} from "../common/constants/static-activations";
import {environment} from "../environment/.env";

export type ActivationLoadParams = {id: string} & Partial<Pick<PublicActivation, 'video' | 'marker'>>;

const fetchActivation = async (id: string) => {
    const url = `${environment.baseUrl}/products/${id}/frontend`;
    const headers = {
        authorization: `Basic ${environment.authToken}`,
        "Content-Type": "application/json",
    };
    const response = await fetch(url, {
        headers,
        credentials: "include",
        method: "GET",
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

export const getActivation = async ({ id, video, marker }: ActivationLoadParams): Promise<PublicActivation> => {
    const localActivation = staticActivations.find(({ name }) => name === id);

    if (localActivation) {
        return localActivation;
    }

    return fetchActivation(id)
        .then((activation) => {
            return {
                ...activation,
                video: video ?? activation.video,
                marker: marker ?? activation.marker,
            }
        });
}
